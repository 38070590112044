/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import SVG from 'react-inlinesvg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { unitConverter as uc, colors } from '../../../styles/base';
import { Heading3 } from '../../atoms/Headings/Headings';

const socialMediasHash = {
  Facebook: {
    icon: '/socialIcons/facebook.svg',
    ShareButton: FacebookShareButton,
  },
  Twitter: {
    icon: '/socialIcons/twitter.svg',
    ShareButton: TwitterShareButton,
  },
  LinkedIn: {
    icon: '/socialIcons/linkedin.svg',
    ShareButton: LinkedinShareButton,
  },
  Email: {
    icon: '/svg/email.svg',
    ShareButton: EmailShareButton,
  },
};

const iconWrapperCss = css`
  background-color: ${colors.transparent};
  border: none;
  cursor: pointer;
`;

const shareItemCss = css`
  margin: 0 ${uc('15px')} 0;
`;

const containerCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const iconsContainerCss = css`
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
`;

const ShareWidget = ({ iconSize, socialMedias, title, url }) => {
  const iconCss = css`
    svg {
      width: ${uc(`${iconSize}px`)};
      height: ${uc(`${iconSize}px`)};
      fill: ${colors.primary};

      & :focus,
      :hover {
        fill: ${colors.primaryHover};
      }
    }

    .cls-1 {
      filter: none;
      fill: ${colors.primary};

      & :focus,
      :hover {
        fill: ${colors.primaryHover};
      }
    }
  `;
  return (
    <div css={containerCss}>
      <Heading3>{title}</Heading3>
      <div css={iconsContainerCss}>
        {socialMedias.map(socialMedia => {
          const { icon, ShareButton } = socialMediasHash[socialMedia.name];
          return (
            <ShareButton
              data-testid={`share-${socialMedia.name}`}
              key={socialMedia.name}
              css={shareItemCss}
              url={url}
            >
              <span css={iconCss}>
                <SVG src={icon} />
              </span>
            </ShareButton>
          );
        })}
        <CopyToClipboard text={url}>
          <button
            data-testid="copy-clipboard"
            css={iconWrapperCss}
            type="button"
          >
            <span css={iconCss}>
              <SVG src="/svg/copy.svg" />
            </span>
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

ShareWidget.propTypes = {
  iconSize: PropTypes.number,
  title: PropTypes.string.isRequired,
  socialMedias: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  url: PropTypes.string.isRequired,
};

ShareWidget.defaultProps = {
  iconSize: 40,
};

export default ShareWidget;
