import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import {
  colors,
  fontFamilies,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Link from '../../atoms/Link/Link';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';

const relatedResourceCss = css`
  display: block;
  max-width: ${uc('360px')};
  margin-bottom: ${uc('25px')};

  a {
    color: ${colors.primary};
    font-weight: ${fontWeights.bold};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.copy};

    :hover {
      text-decoration: underline;
    }
  }

  img {
    display: block;
    margin-bottom: ${uc('15px')};
  }
`;

const RelatedResource = ({ resource }) => {
  return (
    <div css={relatedResourceCss} key={resource._id}>
      <Link to={resource.route.slug.current}>
        <Image
          src={sanityImage(resource.logo.asset._id)
            .auto('format')
            .size(360, 200)
            .url()}
        />
        {resource.title}
      </Link>
    </div>
  );
};

RelatedResource.propTypes = {
  resource: PropTypes.instanceOf(Object).isRequired,
};

export default RelatedResource;
